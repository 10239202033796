import { render } from "react-dom";
import "./styles/index.scss"
import "./styles/viewer.scss"

import App from "./components/App"


render(
    <App />,
    document.getElementById("root")
)

