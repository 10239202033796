import { getColorSuffixFromLine } from "../utils/colorUtil";

const Breadcrumb = ({navigation, line}) => {
    let color = getColorSuffixFromLine(line);
    return (
        <div className={`panel-arborescence panel-arborescence--${color}`}>
        <nav>
            <ol className="arborescence-list">
                {navigation.map(
                    (mop)=><li key={mop.filenameToOpen} className="arborescence-list-item"> {mop.filenameToOpen} </li>
                    ) }
            </ol>
        </nav>
        </div>
    );
}

export default Breadcrumb;




