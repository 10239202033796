export function addHandlerOpenConsigne(handler, secondaryHandler, errHandler, regexToSecondaryHandler = /^[a-z]{2,4}\d{2,4}(-|_)ligne/gmi , isSecondaryPossible = false) {

    let ocs = document.querySelectorAll(`#viewer v\\:cp[v\\:val*='@open-consigne@']`) // OK
    
    ocs.forEach(oc => {
        let customProps = oc.parentElement;
        let gParent = customProps.parentElement;

        let possibleNamesEls = customProps.querySelectorAll("[v\\:nameu = 'RefMop']");
        let filenameEl = possibleNamesEls[possibleNamesEls.length - 1];

        if (!filenameEl) {
            gParent.addEventListener("click", () => errHandler(`open-consigne : ${filename ?? "err : no-name"}`));
            return true;
        }
        let filename = filenameEl.getAttribute("v:val");

        if (!filename || filename === "VT4()") {
            gParent.addEventListener("click", () => errHandler(`open-consigne : ${filename ?? "err : no-name"}`));
            return true;
        }

        // "VT4(myfile.pdf)" -> "myfile"
        filename = filename.slice(4, -5);
        if (isSecondaryPossible && regexToSecondaryHandler && secondaryHandler) {

            if (filename.match(regexToSecondaryHandler)) {
                gParent.addEventListener("click", () => secondaryHandler(filename));
            } else {
                gParent.addEventListener("click", () => handler(`${filename ?? "err : no-name"}`, gParent.id));
            }

        } else {
            gParent.addEventListener("click", () => handler(`${filename ?? "err : no-name"}`, gParent.id));
        }
        gParent.classList.add("cliquable")

    })
}

export function addHandlerEnd(handler) {

    let ends = document.querySelectorAll(`#viewer v\\:cp[v\\:val*='@end@']`) // OK
    
    ends.forEach(end => {

        // first parent is the custom props, second is the g
        let gParent = end.parentElement.parentElement;

        gParent.addEventListener("click", () => handler("Called from end"));
        gParent.classList.add("cliquable")
    });
}

export function addHandlerAnchor(handler, errHandler) {
    let anchors = document.querySelectorAll(`#viewer v\\:cp[v\\:val*='@anchor@']`) // OK
    
    anchors.forEach(anchor => {

        // first parent is the custom props, second is the g
        let gParent = anchor.parentElement.parentElement;
        let anchorTextContent = gParent.querySelector("desc").textContent;
        // remove any text from the anchor text content
        // that is not a number
        anchorTextContent = anchorTextContent.replace(/[^\d]/g, "");
        let nbAnchor = parseInt(anchorTextContent);

        gParent.addEventListener("click", () => isNaN(nbAnchor) ? errHandler(`${nbAnchor} n'est pas un numéro de page valide`) : handler(nbAnchor));
        gParent.classList.add("cliquable")
    });
}

export function addHandlerAnchorTarget(handler, errHandler) {
    let anchors = document.querySelectorAll(`#viewer v\\:cp[v\\:val*='@anchor-target@']`) // OK
    
    anchors.forEach(anchor => {

        // first parent is the custom props, second is the g
        let gParent = anchor.parentElement.parentElement;
        let nbAnchor = gParent.querySelector("desc").textContent;
        gParent.addEventListener("click", () => handler(`Called from page ${nbAnchor}`));
        gParent.classList.add("cliquable")
    });
}

export function addHandlerOpenPoint(handler, errHandler) {
    let openPoints = document.querySelectorAll(`#viewer v\\:cp[v\\:val*='@open-point@']`) // OK

    openPoints.forEach(openPoint => {

        let gParent = openPoint.parentElement.parentElement;
        let cps = openPoint.parentElement;

        let labelPoint = gParent.querySelector("desc").textContent;

        // get the value of infoPoint in the custom props
        let cpsInfo = cps.querySelectorAll("[v\\:nameu = 'infoPoint']");
        if (cpsInfo.length === 0) {
            gParent.addEventListener("click", () => errHandler(`open-point : no-infoPoint`));
            return false;
        }

        let infoPoint = cpsInfo[cpsInfo.length - 1];

        // "VT4(my information)" -> "my information"
        infoPoint = infoPoint.attributes["v:val"].nodeValue.slice(4, -1);

        gParent.addEventListener("click", () => handler({
            label: labelPoint,
            pointInfo: infoPoint
        }));

        gParent.classList.add("cliquable")
    });
}

export function addHandlerIncendie(handler, errHandler) {
    let incendies = document.querySelectorAll(`#viewer v\\:cp[v\\:val*='@incendie@']`) // OK
    incendies.forEach(incendie => {
        let customProps = incendie.parentElement;
        let gParent = incendie.parentElement.parentElement;

        let possibleTypesEls = customProps.querySelectorAll("[v\\:nameu = 'Type']");
        let typeEl = possibleTypesEls[possibleTypesEls.length - 1];

        if (!typeEl) {
            gParent.addEventListener("click", () => errHandler(`open-incendie : ${filename ?? "err : no-type"}`));
            return true;
        }

        let type = typeEl.getAttribute("v:val");

        if (!type || type === "VT4()") {
            gParent.addEventListener("click", () => errHandler(`open-incendie : ${filename ?? "err : no-type"}`));
            return true;
        }

        // "VT4(Desenfumage_station)" -> "Desenfumage_station"
        type = type.slice(4, -1);

        let incendieFilename;

        switch (type) {
            case ('Desenfumage_station'):
                incendieFilename = "Desenfumage%20station.pdf";
                break;
            case ('Desenfumage_Tunnel'):
                incendieFilename = "Desenfumage%20tunnel.pdf";
                break;
            case ('Depart_Feu_Station'):
                incendieFilename = "Depart%20feu%20station.pdf";
                break;
            case ('Evac_Station'):
                incendieFilename = "Evacuation%20station.pdf";
                break;
            case ('Inhi_Desini_Zone'):
                incendieFilename = "Inhi_Desinhi%20zone.pdf";
                break;
            default:
                incendieFilename = null;
        }


        gParent.addEventListener("click", () => handler(incendieFilename));
        gParent.classList.add("cliquable");

    });
}

export function allowResizing() {
    // allow the svg to resize automatically on 
    // based on the size of the container
    let svg = document.querySelector('svg');

    svg.removeAttribute('height');
    svg.removeAttribute('width');

}

export function removeLinks() {
    // Some of the shapes on the svg contain 
    // <a> elements, hence, remove the links
    // they reference to add event listeners
    for (let el of document.querySelectorAll("#viewer a").values()) {
        el.removeAttribute("xlink:href");
    }
}

export function removeTooltips() {
    // Remove the annoying useless tooltips
    // some of the shapes contain
    for (let tooltip of document.querySelectorAll("title").values()) {
        tooltip.remove();
    }
}

export function removeMopBuildingHelp() {
    // Remove all the help added to the visio
    // files, that is exported among the mop 
    // content
    // document.querySelectorAll('g text:contains("Vérifications pour REMI")').remove();
    const svg = document.querySelector("#viewer svg");

    const viewBoxAttr = svg.getAttribute("viewBox");

    if (viewBoxAttr === null || viewBoxAttr === "" ) {
        return;
    }

    const viewBoxAttrValues = viewBoxAttr.split(" ").map((val) => Number(val));
    if (viewBoxAttrValues.length !== 4) {
        return;
    }

    // get the boundaries of the svg
    // [x y width height]
    const leftLimit = viewBoxAttrValues[0];
    const width = viewBoxAttrValues[2];
    const rightLimit = leftLimit + width; // width

    const gNodes = svg.querySelectorAll("g");


    for (const gNode of gNodes) {
        let translate = gNode.getAttribute("transform");
        if (translate == null) {
            continue;
        }

        // get the x cordinate of the translate
        translate = translate.slice(translate.indexOf("(") + 1, translate.indexOf(","));
        let xTranslate = parseInt(translate);

        // -5 is a margin of error to avoid removing nodes
        // that are not the help nodes
        let removeNode = xTranslate < -5 || xTranslate > rightLimit;

        if (removeNode) {
            gNode.remove();
        }
    }

}

export function highlightElement(selector) {
    
    document.querySelector(selector).classList.add("highlighted");
}
