import React, { useState, useEffect, useContext, useRef } from 'react';

import MessageModal from './MessageModal';

import {
    addHandlerOpenConsigne,
    addHandlerEnd,
    addHandlerAnchor,
    addHandlerAnchorTarget,
    addHandlerOpenPoint,
    addHandlerIncendie,
    allowResizing,
    removeTooltips,
    removeMopBuildingHelp,
    removeLinks,
} from '../utils/SVGUtil';
import { VersionContext } from '../context/VersionContext';

function REMISVG({ navigation, setNavigation, secNavigation, setSecNavigation, filename, pageNumber, regexToOpenSecondaryFromMain = /^[a-z]{2,4}\d{2,4}(-|_)ligne/gmi }) {

    const { version } = useContext(VersionContext);

    const [isPointModalOpen, setIsPointModalOpen] = useState(false);
    const [pointModalContent, setPointModalContent] = useState(null);

    const [isRFModalOpen, setIsRFModalOpen] = useState(false);

    const svgContainer = useRef(null);

    const handleOpenConsigne = (filenameToOpen, shapeId) => {

        // Add the shapeId to the last mop of the navigation array
        // Add the filename to the navigation array
        setNavigation((prevNavigation) => {
            const newNavigation = [...prevNavigation];
            const lastElement = newNavigation.pop();
            newNavigation.push({ ...lastElement, shapeToHighlight: shapeId });
            newNavigation.push({ filenameToOpen, navigationIntra: [0] });
            return newNavigation;
        });
    }

    const handleOpenSecondary = (filenameToOpen) => {
        // Add the filename to the secNavigation array
        setSecNavigation((prevSecNavigation) => [...prevSecNavigation, { filenameToOpen, navigationIntra: [0] }]);
    }

    const handleEnd = () => {
        setNavigation((prevNavigation) => {
            // Remove the last mop of the navigation array
            return [...prevNavigation.slice(0, prevNavigation.length - 1)]
        });
    }
    const handleOpenPoint = ({ label: pointLabel, pointInfo: pointInfo }) => {
        setPointModalContent({ label: pointLabel, info: pointInfo });
        setIsPointModalOpen(true);
    }

    const handleOpenPDF = (filename) => {

        let _url = `./api/pdf/${version.value_line}/${filename}`
        window.open(_url, "_blank");

    }
    const handleClosePoint = () => {
        setPointModalContent(null);
        setIsPointModalOpen(false);
    }

    const handleCloseRF = () => {
        setIsRFModalOpen(false);
    }

    const handleAnchor = (numberAnchor) => {
        setNavigation((prevNavigation) => {

            // Add the numberAnchor to the navigationIntra array of the last mop of the navigation array
            return [...prevNavigation.slice(0, prevNavigation.length - 1),
            {
                ...prevNavigation[prevNavigation.length - 1],
                navigationIntra: [...prevNavigation[prevNavigation.length - 1]?.navigationIntra, numberAnchor],
                shapeToHighlight: null
            }
            ]
        });
    }
    const handleAnchorTarget = () => {
        setNavigation((prevNavigation) => {

            // Remove the last numberAnchor of the navigationIntra array of the last mop of the navigation array
            const newNavigation = [...prevNavigation];
            const lastElement = newNavigation.pop();
            const newNavigationIntra = [...lastElement.navigationIntra];

            newNavigationIntra.pop();
            newNavigation.push({ ...lastElement, navigationIntra: newNavigationIntra, shapeToHighlight: null });

            return newNavigation;
        });
    }
    const goToMenu = () => {
        setNavigation([])
    }

    const openRappelsFonctionnels = () => {
        setIsRFModalOpen(true);
    }

    const canOpenSecondaryMopWithinOpenMop = () => {
        // secNavigation is not null nor undefined on the Main Viewer
        const isAlreadySecondaryMop = secNavigation===null || secNavigation===undefined;
        const possible = version.value_role !== "AgentDeLigne" && version.value_role !== "GES" && !isAlreadySecondaryMop;;
        return possible;
    }

    useEffect(() => {
        filename = encodeURIComponent(filename);
        let page_id = pageNumber >= 0 ? (pageNumber + 3) : "RF";

        let controller = new AbortController();
        // Fetch the SVG document from the source
        fetch(
            `./api/mop/${version.value_line}/${filename}/${page_id}`,
            {
                signal: controller.signal
            })
            .then(response => {

                if (response.ok) {
                    return response.text()
                }
                if (response.status === 404) {
                    throw new Error(`Le Mop ${filename} n'est présent dans la base de données des Mops ${version.label}. Erreur : 404.`);
                }
                if (response.status === 401) {
                    throw new Error(`Vous n'avez pas de droit d'accès à ce fichier. Connectez-vous ou changez de compte. Erreur : 401.`);
                }
                if (response.status === 500) {
                    throw new Error(`Erreur interne dans le serveur. Erreur : 500.`);
                }
                if (response.status === 403) {
                    throw new Error(`Vous n'avez pas de droit d'accès à ce fichier. Connectez-vous ou changez de compte. Erreur : 403.`);
                }
                throw new Error("Network response was not ok. Erreur : " + response.status);
            })
            .then(svgText => {
                // remove <foreignObject> tags and its content
                svgText = svgText.replace(/<foreignObject[\s\S]*?<\/foreignObject>/g, "");
                svgText = svgText.replace(/"\s+/g, '"');
                svgContainer.current.innerHTML = svgText;
            })
            .then(() => {

                let canOpenSecondaryMop = canOpenSecondaryMopWithinOpenMop();

                allowResizing();
                removeTooltips();
                removeMopBuildingHelp();
                removeLinks();
                addHandlerOpenConsigne(handleOpenConsigne, handleOpenSecondary, console.error, regexToOpenSecondaryFromMain, canOpenSecondaryMop);
                addHandlerEnd(handleEnd);
                addHandlerAnchor(handleAnchor);
                addHandlerAnchorTarget(handleAnchorTarget);
                addHandlerOpenPoint(handleOpenPoint);
                addHandlerIncendie(handleOpenPDF, console.error);
                if (navigation.length > 0 && navigation[navigation.length - 1]?.shapeToHighlight !== null && navigation[navigation.length - 1]?.shapeToHighlight !== undefined) {
                    document.getElementById(navigation[navigation.length - 1]?.shapeToHighlight)?.classList.add("highlighted");
                }

                window.scrollTo(0, 0)
            })
            .catch(error => {
                console.error("There has been a problem with your fetch operation:", error);
                if (error.name === "AbortError") return;
                svgContainer.current.innerHTML = `<p>${error}</p>`;
            });

        return () => {
            controller.abort();
        };
    }, [navigation]);

    return (
        <div className='viewer-container'>

            <div ref={svgContainer} id={"viewer"} />
            {
                isPointModalOpen && (
                    <MessageModal
                        isOpen={isPointModalOpen}
                        handleClose={handleClosePoint}
                        content={pointModalContent} />
                )
            }

        </div>
    );
}

export default REMISVG;
