export function getColorSuffixFromLine(value_line) {
    let color_suffix = "blue";
    switch (value_line) {
        case "ligne-a":
            color_suffix = "red";
            break;
        case "ligne-b":
            color_suffix = "blue";
            break;
        case "ligne-c":
            color_suffix = "yellow";
            break;
        case "ligne-d":
            color_suffix = "green";
            break;
        case "ligne-nrj":
            color_suffix = "yellow";
            break;
        default:
            color_suffix = "blue";
            break;
    }
    return color_suffix;
}