import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'red',
    border: '2px solid #000',
    boxShadow: 24,
    paddingBottom: 2,
    textAlign: "center",
    color:"white",
    fontWeight: "bold"
};

const contentStyle = {    
    bgcolor: 'background.paper',
    borderTop: '2px solid #000',
    borderBottom: '2px solid #000',
    color: "black",
    textAlign: "left",
    p: 2,
};

const MessageModal = ({
    isOpen,
    handleClose,
    content
}) => {
    let bgColor = "#04AA6D";
    
    if (content.label?.startsWith("S")) {
        bgColor = "#f44336";
    } else if (content.label?.startsWith("M")) {
        bgColor = "#00bae4";
    }
    style.bgcolor = bgColor;
    return (

        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {content.label}
                </Typography>
                    <Box sx={contentStyle}>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {content.info}
                        </Typography>
                    </Box>
                </Box>
        </Modal>

    );
}
export default MessageModal;