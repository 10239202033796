import "../styles/index.scss";

import { useState, useEffect } from "react";

import Viewer from "./Viewer";
import SecondaryViewer from "./SecondaryViewer";

import { UserContext } from "../context/UserContext";
import { VersionContext } from "../context/VersionContext";
import { MainNavigationContext } from "../context/MainNavigationContext";
import { SecondaryNavigationContext } from "../context/SecondaryNavigationContext";

import REMIHeader from "./REMIHeader"
import REMIMenu from "./REMIMenu";
import Breadcrumb from "./Breadcrumb";

import MPL16 from "./MPL16";

const App = () => {
    const [user, setUser] = useState(null);
    const [version, setVersion] = useState(""); // {"label": "Régulation Ligne A", "value_line": "ligne-a", "value_role": "Regulation"}
    const [mainNavigation, setMainNavigation] = useState([]);
    const [secondaryNavigation, setSecondaryNavigation] = useState([]);
    const [allowedVersions, setAllowedVersions] = useState([]);

    /*
        mainNavigation : [
            {
                filenameToOpen: "filename",
                navigationIntra : [
                    0,2,4
                ]
            }
        ]

        secondaryNavigation : [
            {
                filenameToOpen: "filename",
                navigationIntra : [
                    0,2,4
                ]
            }
        ]
    */
    useEffect(async () => {

        const controller = new AbortController();
        const user = await fetch(
            `.auth/me`,
            {
                signal: controller.signal
            });
        const userDataJson = await user.json();

        if (!userDataJson?.clientPrincipal) {
            return;
        }
        setUser(userDataJson.clientPrincipal);
        // fetch versions from user roles
        // by the users have "authenticated" and "anonymous" roles + one role
        const userRole = userDataJson.clientPrincipal.userRoles.filter(role => role !== "anonymous" && role !== "authenticated")[0];
        if (userRole === null || userRole === undefined) {
            console.error(`User ${userDataJson.clientPrincipal.userDetails} has no role`)
            return;
        }

        const versionsForUser = await fetch(
            `/api/versions/${userRole}`,
            {
                signal: controller.signal
            });

        if (versionsForUser.status !== 200) {
            console.error(`Error while fetching versions for ${userRole}`)
            return;
        }
        const versionsData = await versionsForUser.json();
        // set version to "default_version"

        setVersion(versionsData.default_version);

        let accessibleVersions = versionsData.allowed_versions ? versionsData.allowed_versions : [];
        setAllowedVersions(accessibleVersions);

        return () => {
            controller.abort();
        }
    }, [])

    return (
        <>
            <UserContext.Provider value={{ user }}>
                <VersionContext.Provider value={{ version, setVersion }}>
                    <MainNavigationContext.Provider value={{ mainNavigation, setMainNavigation }}>
                        <SecondaryNavigationContext.Provider value={{ secondaryNavigation, setSecondaryNavigation }}>
                            <REMIHeader user={user} setUser={setUser} allowedVersions={allowedVersions} />
                            <main>

                                {
                                    version && mainNavigation.length == 0 && secondaryNavigation.length == 0 &&
                                    <REMIMenu />
                                }
                                {!user &&
                                    <div className="remi-container">
                                        <p className="remi-text">
                                            {
                                                `Réglementation Exploitation Métro Informatisée`
                                            }
                                        </p>
                                        <MPL16 />
                                    </div>
                                }
                                {
                                    mainNavigation.length > 0 && !secondaryNavigation.length > 0 &&
                                    <>
                                        <Breadcrumb navigation={mainNavigation} line={version.value_line} />
                                        <Viewer />
                                    </>
                                }
                                {
                                    secondaryNavigation.length > 0 &&
                                    <>
                                        <Breadcrumb navigation={secondaryNavigation} />
                                        <SecondaryViewer />
                                    </>
                                }
                            </main>
                        </SecondaryNavigationContext.Provider>
                    </MainNavigationContext.Provider>
                </VersionContext.Provider>
            </UserContext.Provider>
        </>
    );
};
export default App;