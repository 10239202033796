import MPL16png from "../assets/MPL16.png";

const MPL16 = () => {
    return (
        <div className="imgMPL16">
            <img src={MPL16png} alt="MPL16" />
        </div>
    )
}

export default MPL16;