import React, { useState, useContext } from "react";
import { SecondaryNavigationContext } from "../context/SecondaryNavigationContext";
import { VersionContext } from "../context/VersionContext";
import { getColorSuffixFromLine } from "../utils/colorUtil";
import REMISVG from "./REMISVG";


function SecondaryViewer() {
  const { secondaryNavigation, setSecondaryNavigation } = useContext(SecondaryNavigationContext);
  const {version} = useContext(VersionContext);
  const [isRFOpen, setIsRFOpen] = useState(false);
  const closeSecondaryMop = () => {
    setSecondaryNavigation([]);
  }

  let lastNavigationElement = secondaryNavigation[secondaryNavigation.length - 1];

  let lastMop = (lastNavigationElement) ? lastNavigationElement.filenameToOpen : ''; 

  let lastPage = (lastNavigationElement) ? lastNavigationElement.navigationIntra.slice(-1)[0] : 0;

  let color = getColorSuffixFromLine(version.value_line);

  const openRF = () => {
    setSecondaryNavigation((prevNavigation) => {

      // Add the negative number to the end of the array
      return [...prevNavigation.slice(0, prevNavigation.length - 1),
      {
        ...prevNavigation[prevNavigation.length - 1],
        navigationIntra: [...prevNavigation[prevNavigation.length - 1]?.navigationIntra, -1]
      }
      ]
    });
    setIsRFOpen(true);
  }

  const closeRF = () => {
    setSecondaryNavigation((prevNavigation) => {

      // Remove the last number of the navigationIntra array of the last mop of the navigation array
      const newNavigation = [...prevNavigation];
      const lastElement = newNavigation.pop();
      const newNavigationIntra = [...lastElement.navigationIntra];

      newNavigationIntra.pop();
      newNavigation.push({ ...lastElement, navigationIntra: newNavigationIntra });

      return newNavigation;
    });
    setIsRFOpen(false);
  }
  const handlerBtnRF = isRFOpen ? closeRF : openRF;

  return (
    <div className="secondary-viewer-wrapper viewer-wrapper">
      <p className="title-secondary-mop"> MOP pour consultation</p>
      <div className="mop-buttons-container">
        <button className={`mop-button-secondary--${color}`} onClick={handlerBtnRF}>
          {isRFOpen ? 'Fermer ' : 'Ouvrir '}
          R. Fonctionnels
        </button>
        <button className={`mop-button-secondary--${color}`} onClick={closeSecondaryMop}> Fermer MOP de support</button>
      </div>
      <REMISVG navigation={secondaryNavigation} setNavigation={setSecondaryNavigation} filename={lastMop} pageNumber={lastPage} />
    </div>
  )
}

export default SecondaryViewer;