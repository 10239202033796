import { useState, useEffect, useContext } from 'react';
import REMISubMenu from './REMISubMenu';
import MPL16 from './MPL16';
import Searchbar from './Searchbar';
import { VersionContext } from '../context/VersionContext';
import { getColorSuffixFromLine } from '../utils/colorUtil';


const REMIMenu = () => {

  const [menuCategories, setMenuCategories] = useState(null);
  const [subMenuFilename, setSubMenuFilename] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mopCounterByCategory, setMopCounterByCategory] = useState({});
  const { version } = useContext(VersionContext);
  let btnColor = getColorSuffixFromLine(version.value_line);
  useEffect(() => {
    if (version === null) return;
    let controller = new AbortController();
    setLoading(true);
    let counterByCategory = {};
    const countNumberOfMops = async (category, counterByCategory) => {
      try {
        const subMenuResponse = await fetch(`/api/menu/${version.value_line}/submenu/${category.fichier}`)
        const subMenu = await subMenuResponse.json();
        const subCategoriesList = subMenu.sub_categories;
        let mopCounter = 0;
        for (const subCategory of subCategoriesList) {
          mopCounter += subCategory.mops_sub_categorie.length;
        }
        counterByCategory[category.fichier] = mopCounter;
      }
      catch (error) {
        console.error(error);
      }
    }
    fetch(
      `/api/menu/${version.value_line}/${version.value_role}`,
      {
        signal: controller.signal
      }
    )
      .then(response => response.json())
      .then(data => {

        if (data.err_msg) {
          setMenuCategories(null);
          setSubMenuFilename(null);
          setError(true);
          return;
        }
        setMenuCategories(data);
        if (data.categories_menu) {
          const promises = data.categories_menu.map(async category => {
            if (category.afficher_nombre_mops === true) {
              await countNumberOfMops(category, mopCounterByCategory);
            }
          });
  
          Promise.all(promises).then(() => {
            setMopCounterByCategory({ ...mopCounterByCategory });
          });
          // submenu is closed by default
          setSubMenuFilename(null);
          setLoading(false);
          setError(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") return;
        setLoading(false);
        setError(true);
      });
    return () => {
      controller.abort();
    };
  }, [version]);

  useEffect(() => {
    if (searchResults === null || searchResults === undefined) return;
    if (searchResults.length >= 1) {
      setSubMenuFilename(null);
    }
  }, [searchResults]);

  const handleClick = async (btnFilename) => {
    if (btnFilename === subMenuFilename) {
      setSubMenuFilename(null);
    } else {
      setSubMenuFilename(btnFilename);
    }
  };
  return (
    <div className='menu-wrapper'>

      <Searchbar line={version?.value_line} menuCategories={menuCategories} setSearchResults={setSearchResults} />

      <div className='menu-btns'>
        {menuCategories &&


          menuCategories?.categories_menu?.map((item, index) => (
            /* btn-${index % 10 + 1} to match classes defined in the scss files */
            <button className={`btn-${index % 10 + 1}--${btnColor}`} key={index} onClick={() => handleClick(item.fichier)}>
              {item.afficher_icone_attention && <span className="attention-icon">&#9888;</span>}
              
              {item.nom_bouton}
              
              { item.afficher_nombre_mops && mopCounterByCategory[item.fichier] &&
              <span className="mop-counter">
                  ({mopCounterByCategory[item.fichier]})
              </span>
              }
            </button>
          ))
        }

      </div>
      {loading &&

        <div className="loader">
          {error ?
            `La base de données ne contient pas de menu pour '${version?.label}'` :
            "En cours de chargement..."
          }
        </div>
      }

      {
        (subMenuFilename) &&
        <REMISubMenu filename={subMenuFilename} />
      }
      {(searchResults?.length > 0 && !subMenuFilename) &&
        <REMISubMenu mop_list={searchResults} />
      }

      {(searchResults?.length == 0 && !subMenuFilename) &&
        <div>
          <h3> Aucun résultat trouvé </h3>
        </div>
      }

      {
        !(subMenuFilename || searchResults) &&
        <MPL16 />
      }
    </div>
  );
};

export default REMIMenu;
