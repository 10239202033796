import React, { useState, useEffect, useContext } from 'react';
import { VersionContext } from '../context/VersionContext';
import { getColorSuffixFromLine } from '../utils/colorUtil';
import { MainNavigationContext } from '../context/MainNavigationContext';
import { SecondaryNavigationContext } from '../context/SecondaryNavigationContext';

const REMISubMenu = ({ filename, mop_list }) => {
    const [listSubCategories, setListSubCategories] = useState(null);
    const [loading, setLoading] = useState(false);
    const { setMainNavigation } = useContext(MainNavigationContext);
    const { setSecondaryNavigation } = useContext(SecondaryNavigationContext);
    const { version } = useContext(VersionContext);
    let color = getColorSuffixFromLine(version?.value_line);
    useEffect(() => {

        if (mop_list) {
            setListSubCategories(

                {
                    "sub_categories": [
                        {
                            "titre_sub_categorie": "Résultats de la recherche",
                            "mops_sub_categorie": [...mop_list]
                        }
                    ]
                }
            );
            return;
        };
        if (version.value_line === undefined || version.value_line === null) return;
        setLoading(true);
        setListSubCategories(null);

        const controller = new AbortController();
        fetch(
            `/api/menu/${version.value_line}/submenu/${filename}`,
            {
                signal: controller.signal
            })
            .then(response => response.json())
            .then(data => {
                // if no data, don't update state
                if (!data.err_msg) {
                    setListSubCategories(data);
                }
                else {
                    alert(`Le menu '${filename}' n'est pas présent dans la base de données de la ligne : ${version?.label}. Contactez l'administrateur.`);
                }
                setLoading(false);
            }).catch((error) => {
                if (error.name === "AbortError") return;
                setLoading(false);
            });
        return () => {
            controller.abort();
        }

    }, [filename, version, mop_list])

    const openFirstMop = (filename, setNavigation) => {

        setNavigation((prevNavigation) => [...prevNavigation, { filenameToOpen: filename, navigationIntra: [0] }]);
    }
    const openPdf = (filename) => {
        window.open(`./api/pdf/${version.value_line}/${filename}`, "_blank")
    }

    const regexToSecondaryHandler = /^[a-z]{2,4}\d{2,4}(-|_)ligne/gmi;

    return (
        <div>
            <div className="menu-panel">
                {listSubCategories &&
                    listSubCategories?.sub_categories.map((subCat, indexSubCat) => {
                        if (subCat.mops_sub_categorie?.length > 0) {
                            subCat.mops_sub_categorie.sort((a, b) => {
                                if (a.nom_mop < b.nom_mop) {
                                    return -1;
                                }
                                if (a.nom_mop > b.nom_mop) {
                                    return 1;
                                }
                                return 0;
                            })

                            return (
                                <div key={subCat.titre_sub_categorie}>
                                    <h3>{subCat.titre_sub_categorie}</h3>

                                    <ul className="menu-mops-list">
                                        {subCat.mops_sub_categorie &&
                                            subCat.mops_sub_categorie.map((mopItem, indexMop) => {
                                                if (mopItem.est_pdf) {
                                                    return (
                                                        <li
                                                            className={`menu-mops-list-item cliquable menu-mops-list-item--${mopItem?.est_nouveau ? `important` : color}`}
                                                            key={mopItem.fichier_mop}
                                                            onClick={() => openPdf(mopItem.fichier_mop)}
                                                        >
                                                            {mopItem.nom_mop}
                                                        </li>
                                                    )
                                                }

                                                // if user not AL and mop AL -> setSecondaryNavigation
                                                // setMainNavigation otherwise

                                                if (!(version.value_role === "AgentDeLigne") && mopItem.fichier_mop.match(regexToSecondaryHandler)) {
                                                    return (<li
                                                        className={`menu-mops-list-item cliquable menu-mops-list-item--${mopItem?.est_nouveau ? `important` : color}`}
                                                        key={mopItem.fichier_mop}
                                                        onClick={() => openFirstMop(mopItem.fichier_mop, setSecondaryNavigation)}
                                                    >
                                                        {mopItem.nom_mop}
                                                    </li>)
                                                }

                                                return (
                                                    <li
                                                        className={`menu-mops-list-item cliquable menu-mops-list-item--${mopItem?.est_nouveau ? `important` : color}`}
                                                        key={mopItem.fichier_mop}
                                                        onClick={() => openFirstMop(mopItem.fichier_mop, setMainNavigation)}
                                                    >
                                                        {mopItem.nom_mop}
                                                    </li>
                                                )
                                            }

                                            )
                                        }
                                    </ul>
                                </div>

                            )
                        }
                    })
                }

                {loading &&
                    <div className="loader">
                        Menu en cours de chargement...
                    </div>
                }

            </div>
        </div>
    );
};

export default REMISubMenu;
