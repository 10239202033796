import Logo from "../assets/logo-kl.png"
import LigneA from "../assets/Ligne_A.png"
import LigneB from "../assets/Ligne_B.png"
import LigneC from "../assets/Ligne_C.png"
import LigneD from "../assets/Ligne_D.png"
import NRJ from "../assets/NRJ.png"
import GES from "../assets/GES.png"

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useState, useContext } from "react";
import { MainNavigationContext } from "../context/MainNavigationContext";
import { SecondaryNavigationContext } from "../context/SecondaryNavigationContext";
import { VersionContext } from "../context/VersionContext";
import { Typography } from "@mui/material";

const REMIHeader = ({ user, setUser, allowedVersions }) => {

    const { mainNavigation } = useContext(MainNavigationContext);
    const { secondaryNavigation } = useContext(SecondaryNavigationContext);

    const { version, setVersion } = useContext(VersionContext);
    const [anchorElVersions, setAnchorElVersions] = useState(null);
    const isVersionsOpen = Boolean(anchorElVersions);

    const handleOpenMenuVersions = (event) => {
        if (mainNavigation.length !== 0 || secondaryNavigation.length !== 0) {
            alert("Vous devez être sur la page d'accueil pour changer de version ou de ligne.");
            return;
        }
        setAnchorElVersions(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorElVersions(null);
    };
    const handleOpenAideAdmin = () => {
        window.open("./api/pdf/ligne-b/aide-adm-remi.pdf", "_blank");
        setAnchorEl(null);
    };
    const handleLogout = () => {
        setUser(null);
        setVersion(null);
        window.location.href = "./logout";
    };

    return (
        <div className="homepage-header" >

            <img src={Logo} className="logo" alt="Logo Keolis Lyon" />

            <div className="header-description">
                {user && version && (
                    <>
                        {version.value_line === 'ligne-a' && <img src={LigneA} className="ligne-header" alt="Logo Ligne A" />}
                        {version.value_line === 'ligne-b' && <img src={LigneB} className="ligne-header" alt="Logo Ligne B" />}
                        {version.value_line === 'ligne-c' && <img src={LigneC} className="ligne-header" alt="Logo Ligne C" />}
                        {version.value_line === 'ligne-d' && <img src={LigneD} className="ligne-header" alt="Logo Ligne D" />}
                        {version.value_line === 'ligne-nrj' && <img src={NRJ} className="ligne-header" alt="Logo NRJ" />}
                        {version.value_line === 'ligne-ges' && <img src={GES} className="ligne-header" alt="Logo GES" />}
                        
                        <Typography>
                            {`${version.label.split('-')[0]}`}
                        </Typography>
                    </>
                )}

            </div>
            {user ?
                <>
                    <div>
                        {
                            allowedVersions && allowedVersions.length > 1 &&

                            <Button
                                id="basic-button"
                                aria-controls={isVersionsOpen ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={isVersionsOpen ? 'true' : undefined}
                                onClick={handleOpenMenuVersions}
                            >
                                Versions
                            </Button>
                        }
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElVersions}
                            open={isVersionsOpen}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {
                                version?.value === "Admin" &&
                                <MenuItem onClick={handleOpenAideAdmin}>Aide Admin</MenuItem>
                            }
                            {
                                allowedVersions.map((availableVersion) => {
                                    return (
                                        <MenuItem
                                            key={availableVersion.value_line + availableVersion.label}
                                            onClick={
                                                () => {
                                                    setVersion(availableVersion);
                                                    handleClose();
                                                }
                                            }>
                                            {availableVersion.label}
                                        </MenuItem>
                                    )
                                }
                                )
                            }

                        </Menu>
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleLogout}
                        >
                            Déconnexion
                        </Button>
                    </div>
                </>
                :
                <div className="login-header">

                    <a href="./login" >
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                        >
                            Connectez-vous
                        </Button>
                    </a>
                </div>
            }

        </div>
    );
};

export default REMIHeader;
