import React, { useState, useEffect} from "react";

import OutlinedInput from '@mui/material/OutlinedInput';

const Searchbar = ({ line, menuCategories, setSearchResults }) => {
    const [mopsArray, setMopsArray] = useState([]);
    const [searchbarActive, setSearchbarActive] = useState(false);

    const searchMop = (searchInput) => {
        let results = [];
      
        if (!mopsArray || searchInput.length <= 1) {
            setSearchResults(null);
            return results;
        }
        
        mopsArray.forEach(mop => {
            if  (mop?.nom_mop?.toLowerCase().includes(searchInput.toLowerCase())) 
            {
                results.push(mop);
            }
        });
        results.sort((a, b) => {
            if (a.nom_mop < b.nom_mop) {
                return -1;
            }
            if (a.nom_mop > b.nom_mop) {
                return 1;
            }
            return 0;
        });
        setSearchResults(results);
        return results;
    };
    useEffect(() => {
        if (menuCategories===null || menuCategories===undefined) {
            setMopsArray([]);
            return;
        }
        let controller = new AbortController();
        let mopsArr = [];
        menuCategories.categories_menu.forEach(category => {
            fetch(
                `./api/menu/${line}/submenu/${category?.fichier}`,
                {
                    signal: controller.signal
                })
                .then(response => response.json())
                .then(data => {
                    if (!data || !data?.sub_categories) return;
                    data.sub_categories.forEach(subCategory => {
                        if (!subCategory?.mops_sub_categorie) return;
                        mopsArr = [...mopsArr, ...subCategory.mops_sub_categorie];
                        
                    });
                    setMopsArray([...mopsArr]);            
                });
        });
        
        
        if (mopsArr.length > 0) {
            setSearchbarActive(true);
        } else {
            setSearchbarActive(false);
        }
        return () => {
            controller.abort();
        }
    }, [menuCategories]);
    
    useEffect(() => {
        if (!mopsArray || mopsArray.length <= 0 ) {
            setSearchbarActive(false);
            return;
        }
        setSearchbarActive(true);
    }, [mopsArray]);

    return (
        < div className='searchbar-container'>
            {
                (searchbarActive) &&
                <OutlinedInput
                    placeholder={"Recherche ..."}
                    onChange={(event) => searchMop(event.target.value)}
                    fullWidth={true}
                />
            }
            {
                (!searchbarActive) &&
                <OutlinedInput
                    disabled={true}
                    placeholder={"Recherche Indisponible ..."}
                    onChange={(event) => searchMop(event.target.value)}
                    fullWidth={true}
                />
            }
        </ div>
    );
};

export default Searchbar;