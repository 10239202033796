import React, { useState, useContext } from "react";
import { MainNavigationContext } from "../context/MainNavigationContext";
import { VersionContext } from "../context/VersionContext";
import { SecondaryNavigationContext } from "../context/SecondaryNavigationContext";
import { getColorSuffixFromLine } from "../utils/colorUtil";
import REMISVG from "./REMISVG";


function Viewer() {
  const { mainNavigation, setMainNavigation } = useContext(MainNavigationContext);
  const { version } = useContext(VersionContext);
  const { secondaryNavigation, setSecondaryNavigation } = useContext(SecondaryNavigationContext);
  const [isRFOpen, setIsRFOpen]  = useState(false);

  const closeMainMop = () => {
    setMainNavigation([]);
  }

  const openRF = () => {
    setMainNavigation((prevNavigation) => {

        // Add the negative number to the end of the array
        return [...prevNavigation.slice(0, prevNavigation.length - 1),
        {
            ...prevNavigation[prevNavigation.length - 1],
            navigationIntra: [...prevNavigation[prevNavigation.length - 1]?.navigationIntra, -1 ]
        }
        ]
    });
    setIsRFOpen(true);
  }

  const closeRF = () => {
    setMainNavigation((prevNavigation) => {

        // Remove the last number of the navigationIntra array of the last mop of the navigation array
        const newNavigation = [...prevNavigation];
        const lastElement = newNavigation.pop();
        const newNavigationIntra = [...lastElement.navigationIntra];

        newNavigationIntra.pop();
        newNavigation.push({ ...lastElement, navigationIntra: newNavigationIntra });

        return newNavigation;
    });
    setIsRFOpen(false);
  }

  let lastNavigationElement = mainNavigation[mainNavigation.length - 1];

  let lastMop = (lastNavigationElement) ? lastNavigationElement.filenameToOpen : '';

  let lastPage = (lastNavigationElement) ? lastNavigationElement.navigationIntra.slice(-1)[0] : 0;

  let handlerBtnRF = isRFOpen ? closeRF : openRF;
  let color = getColorSuffixFromLine(version.value_line);
  
  return (
    <div className="viewer-wrapper">

      <div className="mop-buttons-container">
        <button className={`btn-2--${color}`} onClick={handlerBtnRF }>
          { isRFOpen ? 'Fermer ' : 'Ouvrir '}
          R. Fonctionnels 
          </button>
        <button className={`btn-2--${color}`} onClick={closeMainMop}> Revenir sur Menu</button>
      </div>
      {
        // If the navigation array is not empty, display the SVG
        <REMISVG
          navigation={mainNavigation}
          setNavigation={setMainNavigation}

          secNavigation={secondaryNavigation}
          setSecNavigation={setSecondaryNavigation}

          shapeToHighlightId={lastNavigationElement?.shapeToHighlight}

          filename={lastMop}
          pageNumber={lastPage} />
      }

    </div>
  )
}

export default Viewer;